import Head from 'next/head';
import { useRouter } from 'next/router';
import { Fragment } from 'react';

import { useDarkMode } from '~/src/hooks/Common/useDarkMode';



export interface PageHeadProps {
  title: string;
  description: string;
  image?: string;
  imageWidth?: number;
  imageHeight?: number;
  host?: string;
}

export const PageHead = ({
  title,
  description,
  image,
  imageWidth,
  imageHeight,
}: PageHeadProps) => {
  const router = useRouter();
  const { isDark, isInitialized } = useDarkMode();

  const defaultImageWidth = 1200;
  const defaultImageHeight = 630;
  const defaultImage = '/opengraph.jpg';
  const host = process.env.NEXT_PUBLIC_DOMAIN;
  const domain = host?.replace(/^(http|https):\/\//, '');
  const currentURI = `${host}${router.asPath}`;

  const ogImageAbsoluteURI = (() => {
    if (image) return image;

    return `${host}${defaultImage}`;
  })();

  const isBlogPage = router.pathname === '/blog';

  return (
    <Head>
      <title key="title">{ title }</title>

      {
        // 검색엔진의 중복 도메인 방지 정책에 따른 SEO 점수 하락을 막기 위해
        // 블로그 페이지 진입만 noindex 메타 태그를 표시함
        isBlogPage && <meta name="robots" content="noindex" />
      }

      <meta name="google-site-verification" content="N68Q6yocYcHd4aUZRERIZlm-c-1ExCMVn-zRYFygXf0" />
      <meta key="description" name="description" content={description} />
      <link rel="canonical" href={currentURI} key="canonical" />
      <meta key="og:description" property="og:description" content={description} />
      <meta key="og:url" property="og:url" content={currentURI} />
      <meta key="og:image:width" property="og:image:width" content={`${imageWidth ?? defaultImageWidth}`} />
      <meta key="og:image:height" property="og:image:height" content={`${imageHeight ?? defaultImageHeight}`} />
      <meta key="og:image" property="og:image" content={ogImageAbsoluteURI} />

      <link
        key={'favicon_small'}
        rel="shortcut icon"
        type="image/x-icon"
        href="/favicon_boon_web_48_48.jpg"
        sizes="48x48"
      />
      <link
        key={'favicon_medium'}
        rel="shortcut icon"
        type="image/x-icon"
        href="/favicon_boon_web_96_96.jpg"
        sizes="96x96"
      />
      <link
        key={'favicon_large'}
        rel="shortcut icon"
        type="image/x-icon"
        href="/favicon_boon_web_144_144.jpg"
        sizes="144x144"
      />

      {/* 검색엔진에 감지 되기 위한 기본 파비콘입니다. */}
      <link
        key={'favicon_small'}
        rel="shortcut icon"
        type="image/x-icon"
        href="/favicon_boon_web_48_48.jpg"
        sizes="48x48"
      />
      <link
        key={'favicon_medium'}
        rel="shortcut icon"
        type="image/x-icon"
        href="/favicon_boon_web_96_96.jpg"
        sizes="96x96"
      />
      <link
        key={'favicon_large'}
        rel="shortcut icon"
        type="image/x-icon"
        href="/favicon_boon_web_144_144.jpg"
        sizes="144x144"
      />
      {
        isInitialized
          && (
            isDark
              ? (
                // 테마가 변경됨에 따라 서로 다른 파비콘을 보여줘야 합니다.
                <Fragment>
                  <link
                    key={'favicon_small'}
                    rel="shortcut icon"
                    type="image/x-icon"
                    href="/favicon_boon_darkmode_48_48.png"
                    sizes="48x48"
                  />
                  <link
                    key={'favicon_medium'}
                    rel="shortcut icon"
                    type="image/x-icon"
                    href="/favicon_boon_darkmode_96_96.png"
                    sizes="96x96"
                  />
                  <link
                    key={'favicon_large'}
                    rel="shortcut icon"
                    type="image/x-icon"
                    href="/favicon_boon_darkmode_144_144.png"
                    sizes="144x144"
                  />
                </Fragment>
              )
              : (
                <Fragment>
                  <link
                    key={'favicon_small'}
                    rel="shortcut icon"
                    type="image/x-icon"
                    href="/favicon_boon_lightmode_48_48.png"
                    sizes="48x48"
                  />
                  <link
                    key={'favicon_medium'}
                    rel="shortcut icon"
                    type="image/x-icon"
                    href="/favicon_boon_lightmode_96_96.png"
                    sizes="96x96"
                  />
                  <link
                    key={'favicon_large'}
                    rel="shortcut icon"
                    type="image/x-icon"
                    href="/favicon_boon_lightmode_144_144.png"
                    sizes="144x144"
                  />
                </Fragment>
              )
          )
      }

      <meta name="twitter:card" content={ogImageAbsoluteURI} />
      <meta property="twitter:domain" content={domain} />
      <meta property="twitter:url" content={currentURI} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={ogImageAbsoluteURI} />
    </Head>
  );
};
